import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({ trackEvent }) => ({
  onTrackEvent(eventName: string, params: Record<string, string>) {
    if (trackEvent) {
      trackEvent({ eventName, params });
    }
  },
});

export default mapActionsToProps;
