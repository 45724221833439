import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import {
  IQuickActionBarProps,
  QuickActionBarSkinProps,
} from '../QuickActionBar.types';
import QuickActionBarItem from '../../QuickActionBarItem/viewer/QuickActionBarItem';
import { QuickActionBarItemProps } from '../../QuickActionBarItem/QuickActionBarItem.types';
import { useScrollPosition } from '../../../providers/useScrollPosition';

const colorSchemeTypes = {
  black: '0,0,0',
  grey: '128,128,128',
  brand: undefined,
} as const;

export function getPredefinedColor(
  colorScheme: IQuickActionBarProps['colorScheme'],
) {
  return colorSchemeTypes[colorScheme];
}

const TAB_KEY = 'Tab';

const QuickActionBar: React.FC<IQuickActionBarProps> = props => {
  const {
    id,
    skin: SkinComponent,
    skinStyles,
    items = [],
    dynamicActions = [],
    translate,
    onOpen,
    onClose,
    onTrackEvent,
    maxActions,
    hideText,
    alignment,
    isFloating = false,
    colorScheme,
    isColorSchemeInverted,
    isHidden = false,
    shouldSetPositionAbsoluteAtPageBottom,
    onQuickActionBarItemClicked,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;
  const isAlignedLeft = alignment === 'left';
  const structuredActionsCount = items.length;
  const dynamicActionsCount = dynamicActions.length;

  const totalStructuredActionsApplied = Math.min(
    maxActions - dynamicActionsCount,
    structuredActionsCount,
  );
  const totalActionsCount = totalStructuredActionsApplied + dynamicActionsCount;
  const hasActions = totalActionsCount > 0;

  const [isOpen, setOpen] = useState(false);
  const [isAtPageBottom, setIsAtPageBottom] = useState(false);

  function openActionBar() {
    setOpen(true);
  }

  function closeActionBar() {
    setOpen(false);
  }

  function toggleActionBar() {
    if (isOpen) {
      closeActionBar();
    } else {
      openActionBar();
    }
  }

  const onScroll = (atPageBottom: boolean) => {
    setIsAtPageBottom(atPageBottom);
  };
  useScrollPosition(({ currPos }) => onScroll(currPos.isAtPageBottom), []);
  const trackClickEvent = (itemType: string, label: string) => {
    const isLeadEvent = ['phone', 'email', 'whatsapp', 'line'].includes(
      itemType,
    );

    if (isLeadEvent) {
      onTrackEvent('Lead', {
        action: `${itemType} clicked`,
        label: `Mobile Action Bar | ${label}`,
      });
    } else {
      onTrackEvent('CustomEvent', {
        eventCategory: 'Engagement',
        eventAction: `${label} clicked`,
        eventLabel: 'Mobile Action Bar',
      });
    }
  };

  const getActions = () => {
    return items.slice(0, totalStructuredActionsApplied).concat(
      dynamicActions.map(
        (
          { text, itemType, svgString, hasNotifications, color, onActivate },
          index,
        ) => ({
          compId: `dynamicAction-${id}-${index}`,
          text,
          itemType,
          svgString,
          hasNotifications,
          color,
          clickHandler() {
            trackClickEvent(itemType, text);
            onQuickActionBarItemClicked?.({
              type: 'quickActionBarItemClicked',
              item: {
                itemType,
                label: text,
                link: '',
              },
              actionIndex: index,
            });
            closeActionBar();
            onActivate();
          },
        }),
      ),
    );
  };

  const onOpenRef = React.useRef(onOpen);
  const onCloseRef = React.useRef(onClose);

  React.useEffect(() => {
    if (isOpen) {
      onOpenRef.current?.();
    } else {
      onCloseRef.current?.();
    }
  }, [isOpen]);

  const shouldCycleActions = isFloating && totalActionsCount > 1;

  const lastActionRef = React.useRef<HTMLAnchorElement>(null);
  function controlButtonKeyDownHandler(event: React.KeyboardEvent) {
    if (
      shouldCycleActions &&
      isOpen &&
      event.key === TAB_KEY &&
      event.shiftKey
    ) {
      event.preventDefault();
      event.stopPropagation();

      lastActionRef.current?.focus();
    }
  }
  const menuButtonAriaLabel =
    translate &&
    translate(
      'ariaLabels',
      'Mobile_QuickActionBar_AriaLabel_MenuButton',
      'Quick action menu',
    );

  const rootClasses = classNames(
    [skinStyles.root],
    [skinStyles[`action-count-${totalActionsCount}`]],
    {
      [skinStyles['hide-text']]: hideText,
      [skinStyles.closed]: !isOpen,
      [skinStyles['align-left']]: isAlignedLeft,
      [skinStyles.hidden]: isHidden,
    },
  );
  const actionRootClasses = classNames(skinStyles.action, {
    [skinStyles.inverted]: isColorSchemeInverted,
  });
  const skinStyleClasses = {
    root: rootClasses,
    action: actionRootClasses,
    icon: skinStyles.icon,
    svg: skinStyles.svg,
    text: skinStyles.text,
    controlButton: skinStyles.controlButton,
    notifications: skinStyles.notifications,
    dots: skinStyles.dots,
    firstDot: skinStyles.firstDot,
    secondDot: skinStyles.secondDot,
    thirdDot: skinStyles.thirdDot,
    separator: skinStyles.separator,
    actionsContainer: skinStyles.actionsContainer,
    overlay: skinStyles.overlay,
  };

  const controlButtonRef = React.useRef<HTMLButtonElement>(null);

  /**
   * In floatingSkin when you go over the actions with keyboard, after reaching last action
   * focus should go to control button
   */
  function actionKeyDownHandler(event: React.KeyboardEvent) {
    if (event.key === TAB_KEY && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      controlButtonRef.current?.focus();
    }
  }

  function getChildItemsWithSeparators() {
    const children: Array<React.ReactNode> = [];

    const shouldBeFocusable = (isLastItem: boolean) => isFloating && isLastItem;
    const actions = getActions();

    actions.forEach((next, index) => {
      const { itemType, text } = next;
      const isLastItem = index === actions.length - 1;
      const childrenProps: QuickActionBarItemProps = {
        skinStyles: skinStyleClasses,
        clickHandler: () => {
          trackClickEvent(itemType, text);
          onQuickActionBarItemClicked?.({
            item: {
              itemType: next.itemType,
              label: next.text,
              link: next.link?.href || '',
            },
            actionIndex: index,
            type: 'quickActionBarItemClicked',
          });
          closeActionBar();
        },
        ...next,
      };
      if (shouldBeFocusable(isLastItem)) {
        childrenProps.ref = lastActionRef;
        childrenProps.keyDownHandler = actionKeyDownHandler;
      } else {
        childrenProps.ref = null;
      }

      children.push(
        <QuickActionBarItem {...childrenProps} key={next.compId} />,
      );

      if (!isLastItem) {
        children.push(
          <div
            className={skinStyleClasses.separator}
            key={index}
            data-quick-action="separator"
          />,
        );
      }
    });
    return children;
  }

  const rootProps: QuickActionBarSkinProps['rootProps'] = {
    isOpen,
    position:
      shouldSetPositionAbsoluteAtPageBottom && isAtPageBottom
        ? 'absolute'
        : 'fixed',
  };

  const overlayProps: QuickActionBarSkinProps['overlayProps'] = {
    onClick: closeActionBar,
  };

  const controlButtonProps: QuickActionBarSkinProps['controlButtonProps'] = {
    ariaLabel: menuButtonAriaLabel || '',
    ariaExpanded: isOpen,
    onClick: toggleActionBar,
    onKeyDown: controlButtonKeyDownHandler,
  };

  const actionsContainerProps: QuickActionBarSkinProps['actionsContainerProps'] = {
    display: shouldCycleActions && !isOpen ? 'none' : '',
  };

  const hasNotifications =
    !isOpen && dynamicActions.some(action => action.hasNotifications);

  const predefinedColor = getPredefinedColor(colorScheme);

  return (
    <SkinComponent
      data-has-actions={hasActions}
      data-is-open={isOpen}
      rootProps={rootProps}
      overlayProps={overlayProps}
      controlButtonProps={controlButtonProps}
      actionsContainerProps={actionsContainerProps}
      hasNotifications={hasNotifications}
      classes={skinStyleClasses}
      ref={controlButtonRef}
      id={id}
      style={{
        visibility: hasActions ? 'visible' : 'hidden',
        '--predefined-color': predefinedColor,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {getChildItemsWithSeparators()}
    </SkinComponent>
  );
};

export default QuickActionBar;
